.containerInput {
  margin: 20px 0;
  width: 100%;
  position: relative;
}

.input {
  padding-left: 10px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid grey;
}

.input:focus {
  border: 2px solid #9a70c9;
}

.img {
  width: 16px;
  height: 16px;
  fill: #9a70c9;
  position: absolute;
  right: 2px;
  top: 10px;
  background-color: white;
  padding: 2px 7px;
  border-radius: 3px;
}
