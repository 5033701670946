.actionButton {
  margin: 5px 5px;
  border: 1px solid black;
  border-radius: 6px;
  background-color: white;
}

.actionIcon {
  width: 30px;
  height: 30px;
}

.delete,
.edit {
  transition: all 0.5s;
}

.delete:hover {
  background-color: #ce1919;
}

.edit:hover {
  background-color: lightgreen;
}
