.todo {
  width: 600px;
  border-radius: 15px;
  margin-top: 50px;
  background-color: #ffffff;
  box-shadow: 0px 10px 71px -31px rgba(0, 0, 0, 1);
}

.title {
  color: #9a70c9;
  padding-top: 10px;
}

.listWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.contentWrapper {
  width: 90%;
  margin: auto;
  margin-top: 50px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (orientation: portrait) and (max-device-width: 70em) {
  .todo {
    width: 95vw;
  }
}
